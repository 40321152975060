import { ChatThreadList } from '../components/AIChat/Admin/Home';

function AIChatThreadsRoute() {
  return <ChatThreadList />;
}

export const Component = AIChatThreadsRoute;

export function clientLoader() {
  return null;
}
